import { useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

//  ref is the reference to the element whose height and with is required
//  const divRef = useRef(null);
//  const { height, width } = useDimension(divRef);
//  <div ref={divRef}>
export const useDimension = (ref: React.MutableRefObject<any>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resizeObserverRef = useRef<any>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        if (height !== dimensions.height || width !== dimensions.width) {
          setDimensions({ width, height });
        }
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref, dimensions]);
  return dimensions;
};

export const useWidth = (ref: React.MutableRefObject<any>) => {
  const [width, setWidth] = useState(0);
  const resizeObserverRef = useRef<any>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const currentWidth = entry.contentRect.width;
        if (currentWidth !== width) {
          setWidth(currentWidth);
        }
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref, width]);

  return width;
};

export const useHeight = (ref: React.MutableRefObject<any>) => {
  const [height, setHeight] = useState(0);
  const resizeObserverRef = useRef<any>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach((entry) => {
        const currentHeight = entry.contentRect.height;
        if (currentHeight !== height) {
          setHeight(currentHeight);
        }
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref, height]);

  return height;
};
import { message } from "antd";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { first, map } from "rxjs/operators";
import { RefreshTokenResponse } from "@/models/refresh-token-response";
import { TokenClaims } from "@/models/token-claims";
import { RestError } from "@/models/error";
import { t } from "i18next";
import { Buffer } from "buffer";
import { Permission } from "@/models/enum/permission";

declare global {
  interface Window {
    env: any;
  }
}

export class AuthService {
  private storage: Storage | null = null;
  private tokenKey = "accessToken";
  private refreshTokenKey = "refreshToken";
  private isRefreshing = false;
  private refreshTokenSubject = new Subject<string | null>();

  claims = new BehaviorSubject<TokenClaims | null>(null);
  isLogged = new BehaviorSubject<boolean | null>(null);
  isAdmin = this.claims.pipe(map((claims) => claims?.admin ?? false));
  isSelectOrganization = this.claims.pipe(map((claims) => !claims?.organizationId));

  constructor() {
    if (localStorage.getItem(this.tokenKey)) {
      this.storage = localStorage;
    } else if (sessionStorage.getItem(this.tokenKey)) {
      this.storage = sessionStorage;
    }

    if (this.storage) {
      const token = this.getToken();
      if (token) {
        if (this.isExpired(this.getExpire(token))) {
          this.refreshToken().then((newToken) => {
            if (newToken) {
              this.isLogged.next(true);
              this.claims.next(this.getClaims(newToken));
            }
          });
        } else {
          this.isLogged.next(true);
          this.claims.next(this.getClaims(token));
        }
        return;
      }
    }
    this.isLogged.next(false);
  }

  hasPermission = (permission: Permission): Observable<boolean> => {
    return this.claims.pipe(
      map((claims) => {
        if (!claims) {
          return false;
        }
        if (claims.admin === true) {
          return true;
        }
        if (claims.permissions?.includes(permission) === true) {
          return true;
        }
        return false;
      }
      )
    );
  };

  private getToken = (): string | null => {
    if (!this.storage) throw Error("Storage not initialized");

    return this.storage.getItem(this.tokenKey);
  };

  private getRefreshToken = (): string | null => {
    if (!this.storage) throw Error("Storage not initialized");

    return this.storage.getItem(this.refreshTokenKey);
  };

  private setToken = (accessToken: string, refreshToken: string | undefined) => {
    if (!this.storage) throw Error("Storage not initialized");

    this.storage.setItem(this.tokenKey, accessToken);
    if (refreshToken) {
      this.storage.setItem(this.refreshTokenKey, refreshToken);
    }
    if (!this.isLogged.value) {
      this.isLogged.next(true);
    }
    this.claims.next(this.getClaims(accessToken));
  };

  private removeToken = () => {
    if (!this.storage) throw Error("Storage not initialized");

    this.storage.removeItem(this.tokenKey);
    this.storage.removeItem(this.refreshTokenKey);
    if (this.isLogged.value === null || this.isLogged.value) {
      this.isLogged.next(false);
    }
    this.claims.next(null);
  };

  private setHeader = (
    token: string | null,
    init?: RequestInit
  ): RequestInit => {
    const authInit = init || {};

    if (token) {
      authInit.headers = {
        ...authInit.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return authInit;
  };

  private getClaims = (token: string): TokenClaims | null => {
    const split = token.split(".");

    if (split.length < 2) {
      return null;
    }

    try {
      return JSON.parse(
        Buffer.from(token.split(".")[1], "base64").toString()
      ) as TokenClaims;
    } catch (e) {
      return null;
    }
  };

  private getExpire = (token: string): number | null => {
    const split = token.split(".");

    if (split.length < 2) {
      return null;
    }

    try {
      const jwt = JSON.parse(atob(token.split(".")[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
        return jwt.exp * 1000;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  private isExpired = (exp: number | null) => {
    if (!exp) {
      return false;
    }

    return Date.now() > exp;
  };

  private refreshToken = async (): Promise<string | null> => {
    this.isRefreshing = true;

    const refreshToken = this.getRefreshToken();

    const response = await this.authFetch("/auth/refresh-token", {
      method: "POST",
      body: JSON.stringify({ refreshToken }),
    });

    if (!response?.ok || !response?.body) {
      this.removeToken();
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null);
      return null;
    }

    const newTokens = Object.assign(
      new RefreshTokenResponse(),
      await response.json()
    );

    this.setToken(newTokens.accessToken, newTokens.refreshToken);

    this.isRefreshing = false;
    this.refreshTokenSubject.next(newTokens.accessToken);

    return newTokens.accessToken;
  };

  login = async (
    email: string,
    password: string,
    remember: boolean
  ): Promise<boolean | null> => {
    const response = await this.fetch("/auth/authenticate", {
      method: "POST",
      body: JSON.stringify({ email, password }),
    });

    if (!response) {
      return null;
    }

    if (!response?.ok) {
      return false;
    }

    const newTokens = Object.assign(
      new RefreshTokenResponse(),
      await response.json()
    );

    this.storage = remember ? localStorage : sessionStorage;

    this.setToken(newTokens.accessToken, newTokens.refreshToken);

    return true;
  };

  logout = async () => {
    this.authFetch("/auth/revoke-token", { method: "POST" });
    this.removeToken();
  };

  selectOrganization = async (
    organizationId: string
  ): Promise<boolean | null> => {
    const response = await this.authFetch("/auth/select-organization", {
      method: "POST",
      body: JSON.stringify({ organizationId }),
    });

    if (!response?.ok) {
      return false;
    }

    const newTokens = Object.assign(
      new RefreshTokenResponse(),
      await response.json()
    );

    this.setToken(newTokens.accessToken, newTokens.refreshToken);

    return true;
  };

  changePassword = async (
    password: string,
    newPassword: string
  ): Promise<boolean | null> => {
    const response = await this.authFetch("/auth/change-password", {
      method: "POST",
      body: JSON.stringify({ password, newPassword }),
    });

    return !!response?.ok;
  };

  resetPassword = async (email: string): Promise<boolean | null> => {
    const response = await this.fetch("/auth/reset-password", {
      method: "POST",
      body: JSON.stringify({ email }),
    });

    if (!response) {
      return null;
    }

    return response.ok;
  };

  newPassword = async (
    token: string,
    password: string
  ): Promise<boolean | null> => {
    const response = await this.fetch("/auth/new-password", {
      method: "POST",
      body: JSON.stringify({ token, password }),
    });

    if (!response) {
      return null;
    }

    return response.ok;
  };

  fetch = async (
    input: string,
    init?: RequestInit
  ): Promise<Response | null> => {
    const params = {
      ...init,
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
      },
    };

    return fetch(window.env.baseApiUrl + input, params).then(
      async (response) => {
        if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 401
        ) {
          const error = Object.assign(new RestError(), await response.json());
          if (error.message) {
            message.error(error.message, 4);
          } else {
            message.error(t("error.client"), 4);
          }
        }

        if (response.status >= 500) {
          message.error(t("error.server"), 4);
        }

        return response;
      },
      () => {
        message.error(t("error.server"), 4);
        return null;
      }
    );
  };

  authFetch = async (
    input: string,
    init?: RequestInit
  ): Promise<Response | null> => {
    const token = this.getToken();

    return this.fetch(input, this.setHeader(token, init)).then(
      async (response) => {
        if (response?.ok === false) {
          if (response.status === 401) {
            const newToken = await (this.isRefreshing
              ? this.refreshTokenSubject.pipe(first()).toPromise()
              : this.refreshToken());
            if (newToken) {
              return this.authFetch(input, init);
            } else {
              this.removeToken();
            }
          }
        }

        return response;
      }
    );
  };
}

import md5 from "blueimp-md5";
import dayjs from "dayjs";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import i18n, { t } from "i18next";
import { Amount } from "@/models/amount";

export const apiDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const apiDateFormat = "YYYY-MM-DDT[00:00:00Z]";


export const dateTimeFormat = () => t("dateTimeFormat");
export const dateFormat = () => t("dateFormat");

export const gravatar = (email: string, size: number): string => {
  return `https://www.gravatar.com/avatar/${md5(email)}?s=${size}&d=identicon`;
};

export const renderDate = (value?: string) =>
  value ? dayjs(value, apiDateTimeFormat).format(dateFormat()) : "-";

export const renderDateTime = (value?: string) =>
  value ? dayjs(value, apiDateTimeFormat).format(dateTimeFormat()) : "-";

export const renderSeconds = (value?: string) =>
  value ? dayjs(value, apiDateTimeFormat).format(dateTimeFormat()) : "-";

export const renderCurrency = (amount?: Amount) =>
  amount && amount.value !== undefined && amount?.value !== null && amount.currency.symbol !== "" ? amount?.value.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + amount.currency.symbol : "-";

export const renderPercentage = (value?: number) =>
  value !== undefined && value !== null ? value.toLocaleString(i18n.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " %" : "-";

export const renderBoolean = (value?: boolean) =>
  value ? (
    <CheckOutlined style={{ color: "green" }} />
  ) : (
    <CloseOutlined style={{ color: "red" }} />
  );

export const renderStatus = (
  list: any[]
): ((value?: string) => any) => {
  return function (value?: string): any {
    const status = list.find((val) => val.value === value);
    const label = status?.label ?? "N/A";

    if (status?.color) {
      return <span style={{ color: status.color }}>{label}</span>;
    }

    return label;
  };
};

export const trueFalseList = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

export const tokenSeparator = ["\r\n", "\n"];

export const downloadFileFromApiResponse = async (response: Response) => {
  if (response.headers.get('Content-Type') !== "text/csv") return;
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;

  const contentDisposition = response.headers.get('Content-Disposition');
  const fileName = contentDisposition?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim();
  link.download = fileName ? fileName : 'export.csv';
  console.log(fileName)

  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function deepClone<T>(obj: T): T {
  const parsed = JSON.parse(JSON.stringify(obj));
  const res: any = Array.isArray(obj) ? [] : {};
  return Object.assign(res, parsed) as T;
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboards-page {
  padding: 20px;
  height: 100%;
}
.dashboards-page .actions {
  margin-bottom: 20px;
}
@media (min-width: 767px) {
  .dashboards-page .action {
    flex: 0;
    min-width: 200px;
  }
}
.dashboards-page .card {
  background-color: white;
  padding: 30px;
}
.dashboards-page .title {
  margin: 0;
}
.card-selected {
  background-color: #e6f4ff;
  border-color: #1677ff;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/index.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AAHA;EAKI,mBAAA;AACJ;AACE;EAAA;IAEI,OAAA;IACA,gBAAA;EACJ;AACF;AAZA;EAeI,uBAAA;EACA,aAAA;AAAJ;AAhBA;EAoBI,SAAA;AADJ;AAKA;EACE,yBAAA;EACA,qBAAA;AAHF","sourcesContent":[".dashboards-page {\n  padding: 20px;\n  height : 100%;\n\n  .actions {\n    margin-bottom: 20px;\n  }\n  @media (min-width: 767px) {\n    .action {\n      flex: 0;\n      min-width: 200px;\n    }\n  }\n\n  .card {\n    background-color: white;\n    padding: 30px;\n  }\n\n  .title {\n    margin: 0;\n  }\n}\n\n.card-selected {\n  background-color: #e6f4ff;\n  border-color: #1677ff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

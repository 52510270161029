import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Space, Typography } from "antd";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import { authService, dashboardService } from "@/services";
import { useEffect, useState } from "react";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { Link, useParams } from "react-router-dom";
import { Permission } from "@/models/enum/permission";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function DashboardsPage() {
  const dashboardId = useParams().id!!;
  const dashboards = useObservable(dashboardService.dashboards);
  const manageDashboards = useObservable(
    authService.hasPermission(Permission.ManageDashboards)
  );

  useEffect(() => {
    dashboardService.getDashboards();
  }, [dashboardId]);

  const setFavorite = (dashboardId: string, favorite: boolean) => {
    return (e: any) => {
      dashboardService.setFavorite(dashboardId, !favorite, false);
      e.preventDefault();
    };
  };

  if (!dashboards) {
    return <Loader />;
  }

  return (
    <div className="dashboards-page">
      <Title level={3}>Dashboard List</Title>
      <Row className="actions" justify="start" gutter={[8, 8]}>
        {manageDashboards &&
          <Col className="action" sm={8} xs={24}>
            <AddDashboardButton />
          </Col>
        }
      </Row>
      <Row gutter={[16, 16]}>
        {dashboards.map((dashboard) => {
          return (
            <Col xl={8} md={12} sm={12} xs={24} key={dashboard.id}>
              <Link to={`${dashboard.id}`}>
                <div className="card">
                  <Space>
                    <Button
                      icon={
                        <StarFilled
                          style={{
                            color: dashboard.settings?.favorite
                              ? "gold"
                              : "grey",
                            fontSize: "25px",
                          }}
                        />
                      }
                      onClick={setFavorite(
                        dashboard.id,
                        dashboard.settings?.favorite ?? false
                      )}
                      shape="circle"
                      type="text"
                    />
                    <Title className="title" level={3}>
                      {dashboard.name}
                    </Title>
                  </Space>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

function AddDashboardButton() {
  const [newDashboardVisible, setNewDashboardVisible] =
    useState(false);

  const showNewDashboardModal = () => {
    setNewDashboardVisible(true);
  };

  const hideNewDashboardModal = () => {
    setNewDashboardVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewDashboardModal}
      >
        New Dashboard
      </Button>
      <DashboardNewForm
        visible={newDashboardVisible}
        onFinish={hideNewDashboardModal}
      />
    </>
  );
}

function DashboardNewForm(props: {
  visible: boolean;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [selection, setSelection] = useState<string | undefined>();
  const isFetching = useObservable(dashboardService.isFetchingTemplates);
  const templates = useObservable(dashboardService.templates);
  const datasetOptions = useObservable(dashboardService.datasetOptions);

  useEffect(() => {
    dashboardService.getDashboardTemplates();
    dashboardService.getDatasets();
  }, [props.visible]);

  const onSubmit = ({ name, dataset }: { name: string; dataset: string; }) => {
    dashboardService
      .add(name, dataset, selection)
      .then((response) => {
        if (response) {
          message.success("Dashboard created successfully", 4);
          props.onFinish();
          form.resetFields();
          dashboardService.getDashboards();
        }
      });
  };

  const onCancel = () => {
    props.onFinish();
    form.resetFields();
  };

  return (
    <Modal
      width={700}
      open={props.visible}
      onCancel={props.onFinish}
      title="New Dashboard"
      loading={isFetching || !templates}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form labelCol={{ span: 3 }} form={form} onFinish={onSubmit}>
        <Form.Item
          labelAlign="left"
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Dataset"
          name="dataset"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            options={datasetOptions}
            style={{ width: "100%" }}
            disabled={!!selection ? true : false}
          /></Form.Item>
        <Row gutter={[16, 16]}>
          <Col sm={12} xs={24}>
            <Card onClick={() => { setSelection(undefined); }} style={{ cursor: "pointer" }} className={!selection ? "card-selected" : ""}>
              <center>
                <Title level={4}>Blank Dashboard</Title>
              </center>
            </Card>
          </Col>
          {templates.map((template) =>
            <Col key={template.id} sm={12} xs={24}>
              <Card onClick={() => { setSelection(template.id); form.setFieldValue("dataset", template.datasetId); }} style={{ cursor: "pointer" }} className={selection === template.id ? "card-selected" : ""}>
                <center>
                  <Title level={4}>{template.name}</Title>
                </center>
              </Card>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
}
import { FilterType } from "./enum/filter-type";
import { WidgetType } from "./enum/widget-types";

export class Dashboard {
  id!: string;
  name!: string;
  dataset!: Dataset;
  widgets!: Widget[];
  filters!: Filter[];
  settings?: Settings;
}

export class Widget {
  name: string = "";
  type: WidgetType = WidgetType.Card;
  position: Position = new Position();
  settings: string = "";
  value: any;
}

export class Position {
  x: number = 0;
  y: number = 0;
  w: number = 0;
  h: number = 0;
}

export class Filter {
  name!: string;
  customOptions!: boolean;
  options!: any;
  field!: string;
  type!: FilterType;
  multiValue!: boolean;
  customInitialValue!: boolean;
  initialValue!: any;
  filterValue!: FilterValue;
}

export class FilterValue {
  options: FilterOption[] = [];
  value!: any;
}

export class FilterOption {
  label!: string;
  value!: string;
}

export class Dataset {
  id: string = "";
  name: string = "";
  query: string = "";
  fields: string[] = [];
  filters: string[] = [];
}

export class Settings {
  favorite!: boolean;
}

export class VendorCentralCredential {
  id: string = "";
  email: string = "";
  accounts: Account[] = [];
}

export class Account {
  name: string = "";
  marketplace: string = "";
  payeeCode: string = "";
  disabled: boolean = false
  disabledDisputeInvoice: boolean = false
  disputeInvoiceStartDate?: Date;
  disputeInvoiceEndDate?: Date;
  validityStartDate?: Date;
  lastUpdate?: Date;
  updating: boolean = false;
  startUpdateOn: string = "";
  fee: number = 0;
}

export class SelectAccount {
  label: string = "";
  value: string = "";

  constructor(name: string, payeeCode: string) {
    this.label = `(${payeeCode}) ${name}`;
    this.value = name;
  }
}

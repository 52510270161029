import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Permission } from "@/models/enum/permission";
import { User } from "@/models/user";
import { authService, roleService, userService } from "@/services";
import { gravatar } from "@/utils";
import { useObservable } from "@/utils/use-observable";
import "./index.less";

const { Search } = Input;
const { Option } = Select;

export default function UsersPage() {
  const users = useObservable(userService.users);
  const isFetching = useObservable(userService.isFetching);
  const isTogglingStatus = useObservable(userService.isTogglingStatus);
  const [searchText, setSearchText] = useState("");
  const claims = useObservable(authService.claims);
  const manageUser = useObservable(authService.hasPermission(Permission.ManageUsers));

  const columns: ColumnsType<User> = [
    {
      title: "Name",
      key: "name",
      width: "300px",
      sorter: {
        compare: (a: User, b: User) => (a.name > b.name ? -1 : 1),
        multiple: 3,
      },
      render: (_: string, row: User) => (
        <List.Item.Meta
          avatar={<Avatar src={gravatar(row.email, 200)} />}
          title={<Link to={row.id}>{row.name}</Link>}
          description={row.email}
        />
      ),
    },
    {
      title: "Role",
      key: "role",
      width: "100px",
      sorter: {
        compare: (a: User, b: User) => (a.role?.name > b.role?.name ? -1 : 1),
        multiple: 1,
      },
      render: (_: string, row: User) => <Tag>{row.role?.name}</Tag>,
    },
  ];

  if (manageUser) {
    columns.push({
      title: "Actions",
      key: "actions",
      width: "100px",
      render: (_: string, user: User) =>
        claims?.sub !== user.id ? (
          <Button
            type={user.enabled ? "default" : "primary"}
            block
            loading={isTogglingStatus}
            onClick={onChangeStatus(user)}
          >
            {user.enabled ? "Disable" : "Enable"}
          </Button>
        ) : (
          <></>
        ),
    });
  }

  useEffect(() => {
    userService.getUsers();
  }, []);

  const onRefresh = () => {
    userService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  const onChangeStatus = (user: User) => () => {
    (user.enabled
      ? userService.disableUser(user.id)
      : userService.enableUser(user.id)
    ).then((response) => {
      if (response)
        message.success(`User ${user.enabled ? "enabled" : "disabled"}`, 4);
    });
  };

  return (
    <div className="users-page">
      <Row className="actions" justify="start" gutter={[8, 8]}>
        {manageUser && (
          <Col className="action" sm={8} xs={24}>
            <AddUserButton />
          </Col>
        )}
        <Col className="action" sm={8} xs={24}>
          <Button
            style={{ width: "100%" }}
            icon={<SyncOutlined spin={isFetching} />}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search user"
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Table
        style={{ maxWidth: "800px" }}
        columns={columns}
        dataSource={users.filter((user) =>
          user.name.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 600 }}
      />
    </div>
  );
}

function AddUserButton() {
  const [newUserVisible, setNewUserVisible] = useState(false);

  const showNewUserModal = () => {
    setNewUserVisible(true);
  };

  const hideNewUserModal = (added: boolean) => {
    setNewUserVisible(false);
    if (added) {
      userService.getUsers();
    }
  };

  return (
    <>
      <Button
        style={{ width: "100%" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewUserModal}
      >
        New User
      </Button>
      {newUserVisible && (
        <UserNewForm visible={true} onFinish={hideNewUserModal} />
      )}
    </>
  );
}

function UserNewForm(props: {
  visible: boolean;
  onFinish: (added: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const roles = useObservable(roleService.roles);
  const isFetching = useObservable(roleService.isFetching);

  useEffect(() => {
    roleService.getRoles();
  }, []);

  const onSubmit = ({
    name,
    password,
    email,
    role,
  }: {
    name: string;
    password: string;
    email: string;
    role: string;
  }) => {
    userService.addUser(name, password, email, role).then((response) => {
      if (response) {
        message.success("User added", 4);
        props.onFinish(true);
        form.resetFields();
      }
    });
  };

  const onCancel = () => {
    props.onFinish(false);
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={onCancel}
      title="New User"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isFetching}
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: t("error.fieldRequired") },
            { type: "email", message: "This field must be a valid email" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

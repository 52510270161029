import {
  Anchor,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { integrationService } from "@/services";
import "./index.less";
import { ColumnsType } from "antd/lib/table";
import { useObservable } from "@/utils/use-observable";

const { Search } = Input;
const { Title, Text, Link } = Typography;

function ApiToken() {
  const apiToken = useObservable(integrationService.apiToken);
  const apiTokenLoading = useObservable(integrationService.apiTokenLoading);
  const [visible, setVisible] = useState(false);

  const onRotateApiToken = () => {
    integrationService.rotateApiKey().then((response) => {
      if (response) {
        message.success("Api Token rotated", 4);
      }
      hide();
    });
  };

  const hide = () => setVisible(false);

  const show = () => setVisible(true);

  return (
    <Popconfirm
      title="Rotate API Token"
      description="Are you sure to rotate API token?"
      open={visible}
      onConfirm={onRotateApiToken}
      onCancel={hide}
      okText="Yes"
      cancelText="No"
    >
      <Search
        value={apiToken!!}
        readOnly={true}
        enterButton="Rotate Api Token"
        loading={apiTokenLoading}
        onSearch={show}
        style={{ maxWidth: "500px" }}
      />
    </Popconfirm>
  );
}

function Webhook() {
  const url = useObservable(integrationService.webhook);
  const webhookLoading = useObservable(integrationService.webhookLoading);
  const [visible, setVisible] = useState(false);

  const onSaveWebhookUrl = () => {
    integrationService.updateWebhookUrl(url!!).then((response) => {
      if (response) {
        message.success("Webhook url saved", 4);
      }
      hide();
    });
  };

  const setWebhookUrl = (e: ChangeEvent<any>) => {
    integrationService.setWebhookUrl(e.target.value);
  };

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  return (
    <Popconfirm
      title="Save Webhook URL"
      description="Are you sure to save Webhook URL?"
      open={visible}
      onConfirm={onSaveWebhookUrl}
      onCancel={hide}
      okText="Yes"
      cancelText="No"
    >
      <Search
        placeholder="Webhook URL"
        value={url}
        enterButton="Save Webhook url"
        loading={webhookLoading}
        onSearch={show}
        onChange={setWebhookUrl}
        style={{ maxWidth: "700px" }}
      />
    </Popconfirm>
  );
}

export default function IntegrationPage() {
  useEffect(() => {
    integrationService.getIntegration();
  }, []);

  const postRequestExample = `{
    "accountName": "TEST ACCOUNT NAME",
    "data": ...
}`;

  const postRequestResponseExample = `{
  "requestId": "a2633756-248a-11ee-be56-0242ac120002"
}`;

  const postAddInvoicesRequestExample = `{
  "requestId": "...",
  "accountName": "...",
  "payeeCode": "...",
  "date": "...",
  "type": "...",
  "executionTime": ...,
  "status": "...",
  "startDate": "...",
  "endDate": "...",
  "data": {
    "invoices": [
      {
        "number": "CFI1234SC",
        "deliveryDate": "2023-01-23T00:00:00Z",
        "customInfo": {}
      }
    ]
  }
}`;

  const getRequestResponseExample = `{
  "requestId": "a2633756-248a-11ee-be56-0242ac120002",
  "accountName": "TEST ACCOUNT NAME",
  "payeeCode": "123456",
  "date": "2023-01-23T00:00:00Z",
  "type": "DISPUTE_INVOICE",
  "executionTime": 5000,
  "status": "DONE",
  "startDate": "2023-01-23T00:00:00Z",
  "endDate": "2023-01-23T00:00:00Z",
  "data": ...,
  "errorMessage": "",
}`;

  return (
    <div className="integration-page">
      <Row>
        <Col className="integration-main-col" md={16} sm={24}>
          <Title id="api" level={3}>
            API
          </Title>
          <Title id="authentication" level={3}>
            Authentication
          </Title>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Text>Robin API uses API Key to authenticate requests.</Text>
            <Title level={5}>How to get API Key？</Title>
            <Text>This is you Api Key.</Text>
            <ApiToken />
            <Text>
              After you get your API Key, be sure to keep them secure! Do not
              share your secret API Keys in publicly accessible area such as
              Github, client-side code, and so forth.
            </Text>
            <Text strong>
              If you rotate your Api Key, this will change and the old will be
              useless.
            </Text>
            <Divider />
            <Title id="request" level={3}>
              Request
            </Title>
            <Text>All the requests and responses are in JSON string.</Text>
            <Title level={5}>Base URL</Title>
            <Text>
              The base URL of the API is{" "}
              <Text copyable code>
                {window.env.baseApiUrl}
              </Text>
              .
            </Text>
            <Title level={5}>Header</Title>
            <Text>
              All API calls must include the{" "}
              <Text copyable code>
                X-API-KEY
              </Text>{" "}
              header in order to authenticate the usage of Robin API. Replace
              YOUR_API_KEY with your API Key.
            </Text>
            <Text>
              Content-Type for all calls should be application/json. The charset
              parameter provided in Content-Type header is by default set to
              UTF-8, it DOES NOT support any other encodings such like
              ISO-8859-1.
            </Text>
            <Title level={5}>Example</Title>
            <Text code>
              curl -X GET -k "{window.env.baseApiUrl}/..." -H
              "Content-Type:application/json" -H "X-API-KEY:YOUR_API_KEY"
            </Text>
            <Divider />
            <Title id="response" level={3}>
              Response
            </Title>
            <Text>All the responses and requests are in JSON string.</Text>
            <Divider />

            <Title id="requests" level={3}>
              Requests
            </Title>

            <Title id="requests-post" level={4}>
              POST Create request
            </Title>
            <Text code>POST /public-api/v1/requests/:request-type</Text>
            <Text>
              Create a request for a job. Return a request id. When job will be
              finished it will send a request to your configured webhook url.
            </Text>
            <Text>
              Please see the{" "}
              <Link strong href="#webhook">
                Webhook
              </Link>{" "}
              section.
            </Text>
            <Text strong>Request parameters</Text>
            <PostRequestParameters />
            <Text strong>Request example</Text>
            <Text>
              <pre>{postRequestExample}</pre>
            </Text>
            <Text strong>Response parameters</Text>
            <PostRequestResponseParameters />
            <Text strong>Response example</Text>
            <Text>
              <pre>{postRequestResponseExample}</pre>
            </Text>
            <Divider />
            <Title id="requests-get" level={3}>
              GET Get Requests
            </Title>
            <Text code>GET /public-api/v1/requests/:requestId</Text>
            <Text>Get response aboud you dispute invoice request.</Text>
            <Text>
              Please see the{" "}
              <Link strong href="#requests-post">
                POST Create requests
              </Link>{" "}
              section.
            </Text>
            <Text strong>Response parameters</Text>
            <GetRequestResponseParameters />
            <Text strong>Response example</Text>
            <Text>
              <pre>{getRequestResponseExample}</pre>
            </Text>
            <Divider />

            <Title id="requests-add-invoices" level={3}>
              Add Invoices
            </Title>
            <Title id="requests-add-invoices-post" level={4}>
              POST Add Invoices
            </Title>
            <Text copyable code>
              POST /public-api/v1/requests/add-invoice
            </Text>
            <Text>Get request about add invoice.</Text>
            <Text strong>Request parameters</Text>
            <PostAddInvoiceParameters />
            <Text strong>Request example</Text>
            <Text>
              <pre>{postAddInvoicesRequestExample}</pre>
            </Text>

            <Title id="webhook" level={3}>
              Webhook
            </Title>
            <Title level={5}>What is Webhook?</Title>
            <Text>
              Webhook is a way to receive callback functions. Via Webhook, you
              can receive job status asynchronous. When completing requesting
              data, Webhook will respond the data to you through your configured
              URL.
            </Text>
            <Text>
              We make a POST request to the callback URL that you defined in the
              webhook page. The post body contains a JSON string of the below
              data.
            </Text>
            <Text>
              We currently support either HTTP or HTTPS urls, so you can have
              security by using an SSL-enabled url. But keep in mind that your
              endpoint is going to be wide-open on the internet.
            </Text>
            <Webhook />
            <Title level={5}>Body parameters</Title>
            <GetRequestResponseParameters />
            <Title level={5}>Body example</Title>
            <Text>
              <pre>{getRequestResponseExample}</pre>
            </Text>
          </Space>
        </Col>
        <Col md={8} sm={0} xs={0}>
          <Anchor
            items={[
              {
                key: "api",
                href: "#api",
                title: "Api",
                children: [
                  {
                    key: "authentication",
                    href: "#authentication",
                    title: "Authentication",
                  },
                  {
                    key: "request",
                    href: "#request",
                    title: "Request",
                  },
                  {
                    key: "response",
                    href: "#response",
                    title: "Response",
                  },
                  {
                    key: "requests",
                    href: "#requests",
                    title: "Requests",
                    children: [
                      {
                        key: "requests-post",
                        href: "#requests-post",
                        title: "POST",
                      },
                      {
                        key: "requests-get",
                        href: "#requests-get",
                        title: "GET",
                      },
                      {
                        key: "requests-add-invoices",
                        href: "#requests-add-invoices",
                        title: "Add Invoices",
                        children: [
                          {
                            key: "requests-add-invoices-post",
                            href: "#requests-add-invoices-post",
                            title: "POST",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                key: "webhook",
                href: "#webhook",
                title: "Webhook",
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
}

interface RequestDataType {
  name: string;
  type: string;
  description: string;
  required: boolean;
}

function RequestParameters(props: { data: RequestDataType[] }) {
  const columns: ColumnsType<RequestDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      render: (text, record) =>
        record.required ? (
          <Text>
            <Text code>{text}</Text>
            {record.required ? " (required)" : ""}
          </Text>
        ) : (
          <Text code>{text}</Text>
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "100px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "500px",
    },
  ];

  return (
    <Table
      style={{ maxWidth: "800px" }}
      size="small"
      pagination={false}
      columns={columns}
      dataSource={props.data}
      rowKey="name"
      scroll={{ x: 800 }}
    />
  );
}

interface ResponseDataType {
  name: string;
  type: string;
  description: string;
}

function ResponseParameters(props: { data: ResponseDataType[] }) {
  const columns: ColumnsType<ResponseDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      render: (text) => <Text code>{text}</Text>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "100px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "500px",
    },
  ];

  return (
    <Table
      style={{ maxWidth: "800px" }}
      size="small"
      pagination={false}
      columns={columns}
      dataSource={props.data}
      rowKey="name"
      scroll={{ x: 800 }}
    />
  );
}

function PostRequestParameters() {
  const data: RequestDataType[] = [
    {
      name: "accountName",
      type: "String",
      description: "Vendor Central account name",
      required: true,
    },
    {
      name: "data",
      type: "String",
      description: "Request data, every request type has his own structure",
      required: true,
    },
  ];

  return <RequestParameters data={data} />;
}

function PostRequestResponseParameters() {
  const data: ResponseDataType[] = [
    {
      name: "requestId",
      type: "String",
      description: "Request id",
    },
  ];

  return <ResponseParameters data={data} />;
}

function GetRequestResponseParameters() {
  const data: ResponseDataType[] = [
    {
      name: "requestId",
      type: "String",
      description: "Request id",
    },
    {
      name: "accountName",
      type: "String",
      description: "Vendor central account name",
    },
    {
      name: "payeeCode",
      type: "String",
      description: "Main vendor code",
    },
    {
      name: "date",
      type: "Date",
      description: "Request's date / Job creation date (ex. 2023-01-23T00:00:00Z)",
    },
    {
      name: "type",
      type: "String",
      description: "Request's type [DISPUTE_INVOICE|REMITTANCE]",
    },
    {
      name: "executionTime",
      type: "String",
      description: "Execution time in nano seconds's",
    },
    {
      name: "status",
      type: "String",
      description: "Request's status [SENT|DONE|FAILED]",
    },
    {
      name: "startDate",
      type: "Date",
      description: "Start date (ex. 2023-01-23T00:00:00Z)",
    },
    {
      name: "endDate",
      type: "Date",
      description: "End date (ex. 2023-01-23T00:00:00Z)",
    },
    {
      name: "data",
      type: "Object or array of Objects (depends by type)",
      description: "Generic",
    },
    {
      name: "errorMessage",
      type: "String",
      description: "Error message",
    },
  ];

  return <ResponseParameters data={data} />;
}

function PostAddInvoiceParameters() {
  const data: RequestDataType[] = [
    {
      name: "data.invoices.invoiceNumber",
      type: "String",
      description: "Invoice number to add (ex. CFI20003242SC)",
      required: true,
    },
    {
      name: "data.invoices.deliveryDate",
      type: "Date",
      description: "Invoice delivery date (ex. 2023-01-23T00:00:00Z)",
      required: false,
    },
    {
      name: "data.customInfo",
      type: "Array of Objects",
      description: "List of information to add to invoice",
      required: false,
    }
  ];

  return <RequestParameters data={data} />;
}

import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import { authService, dashboardService } from "@/services";
import { useEffect, useRef, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useWidth } from "@/utils/use-dimension";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  StarFilled,
  SettingOutlined,
  PlusOutlined,
  DeleteOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { Dashboard, Filter, Widget } from "@/models/dashboard";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Permission } from "@/models/enum/permission";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib";
import { FilterType, filterTypeList } from "@/models/enum/filter-type";
import { getWidgetTypeLabel, WidgetType, widgetTypeList } from "@/models/enum/widget-types";
import { WidgetCard } from "./widget";
import pieChart from "./icons/pie-chart.png";
import tableChart from "./icons/table-chart.png";
import barChart from "./icons/bar-chart.png";
import lineChart from "./icons/line-chart.png";
import cardChart from "./icons/card-chart.png";

const { Title, Text } = Typography;

export default function DashboardPage() {
  const dashboardId = useParams().id!!;
  const dashboard = useObservable(dashboardService.dashboard);

  useEffect(() => {
    dashboardService.getDashboard(dashboardId);

    return () => {
      dashboardService.disposeDashboard();
    };
  }, [dashboardId]);

  if (!dashboard) {
    return <Loader />;
  }

  return (
    <div className="dashboard-page">
      <DashboardTitle />
      <Divider />
      <Layout />
    </div>
  );
}

function DashboardTitle() {
  const dashboard = useObservable(dashboardService.dashboard)!;
  const isEditing = useObservable(dashboardService.isEditing);
  const manageDashboard = useObservable(authService.hasPermission(Permission.ManageDashboards));
  const router = useNavigate();

  const onEdit = () => {
    dashboardService.editing();
  };

  const onCancel = () => {
    dashboardService.cancelEditing();
  };

  const onSave = () => {
    dashboardService.update();
  };

  const onDelete = () => {
    dashboardService.delete().then((result) => {
      if (result) {
        router("/");
      }
    });
  };

  const setFavorite = () => {
    dashboardService.setFavorite(
      dashboard.id,
      !(dashboard.settings?.favorite ?? false),
      true
    );
  };

  return (
    <Row justify={"space-between"}>
      <Col>
        <Space>
          <Button
            icon={
              <StarFilled
                style={{
                  color: dashboard.settings?.favorite ? "gold" : "grey",
                  fontSize: "25px",
                }}
              />
            }
            onClick={setFavorite}
            shape="circle"
            type="text"
          />
          <Title level={3} style={{ margin: 0 }} editable={isEditing ? {
            onChange: (name: string) => {
              const dd = dashboardService.dashboard.value!!;
              dd.name = name;
              dashboardService.dashboard.next(Object.assign(new Dashboard(), dd));
            }
          } : undefined}>
            {dashboard.name}
          </Title>
        </Space>
      </Col>
      <Col>
        {manageDashboard && !isEditing && (
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={onEdit} shape="circle" />
          </Tooltip>
        )}
        {isEditing && (
          <Space>
            <Tooltip title="Save">
              <Button type="primary" icon={<SaveOutlined />} onClick={onSave} shape="circle" />
            </Tooltip>
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} onClick={onDelete} shape="circle" />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button icon={<CloseOutlined />} onClick={onCancel} shape="circle" />
            </Tooltip>
          </Space>
        )}
      </Col>
    </Row>
  );
}

function Layout() {
  const divRef = useRef(null);
  const width = useWidth(divRef);
  const isEditing = useObservable(dashboardService.isEditing);
  const widgets = useObservable(dashboardService.widgets);

  const onLayoutChange = (newLayout: any) => {
    dashboardService.setLayout(newLayout);
  };

  return (
    <>
      <Filters />
      <div ref={divRef} style={!widgets ? { height: 200 } : {}}>
        {widgets && width > 0 && (
          <ResponsiveGridLayout
            cols={{ lg: 24, md: 18, sm: 12, xs: 8, xxs: 4 }}
            rowHeight={10}
            isDraggable={isEditing}
            isResizable={isEditing}
            width={width}
            onLayoutChange={onLayoutChange}
            draggableCancel=".settings"
          >
            {widgets.map((widget, index) => {
              return (
                <div
                  className="widget"
                  key={index}
                  data-grid={widget.position}
                >
                  <WidgetCard widget={widget} />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        )}
      </div>
    </>
  );
}

function Filters() {
  const isEditing = useObservable(dashboardService.isEditing);
  const filters = useObservable(dashboardService.filters);

  return (
    filters && (
      <Row gutter={[16, 16]}>
        {filters.map((filter) => (
          <Col xl={3} md={8} sm={12} xs={24} key={filter.field}>
            <DashboardFilter
              filter={filter}
            />
          </Col>
        ))}
        {isEditing &&
          <Col xl={3} md={8} sm={12} xs={24}>
            <Space>
              <AddFilterButton />
              <AddWidgetButton />
            </Space>
          </Col>
        }
      </Row>
    )
  );
}

function DashboardFilter(props: {
  filter: Filter;
  isTest?: boolean;
}) {
  const isEditing = useObservable(dashboardService.isEditing);
  const [editing, setEditing] = useState(false);
  const [filterFormVisible, setFilterFormVisible] = useState(false);
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    if (!props.isTest) {
      setEditing(isEditing);
    }
  }, [props.isTest, isEditing]);

  useEffect(() => {
    setValue(props.filter.filterValue.value);
  }, [props.filter]);

  const showFilterFormModal = () => {
    setFilterFormVisible(true);
  };

  const hideFilterFormModal = () => {
    setFilterFormVisible(false);
  };

  const changeFilter = (value: string) => {
    if (!editing && !props.isTest) {
      dashboardService.setFilter(props.filter.name, value);
    }
    if (props.isTest) {
      setValue(value);
    }
  };

  return (
    <>
      {props.filter.type === FilterType.SELECT &&
        <Select
          options={props.filter.filterValue.options}
          value={value}
          onChange={changeFilter}
          placeholder={props.filter.name}
          allowClear={!editing}
          open={editing ? false : undefined}
          suffixIcon={editing ?
            <>
              <Button
                icon={<SettingOutlined />}
                size="small"
                shape="circle"
                type="text"
                onClick={showFilterFormModal}
              />
              <DeleteFilterIcon filter={props.filter} />
            </> : undefined}
          style={{ width: "100%" }}
          tagRender={editing ? (props: CustomTagProps) =>
            <Tag style={{ marginRight: 3 }}>
              <Text>{props.label}</Text>
            </Tag>
            : undefined}
          mode={
            props.filter.multiValue
              ? props.filter.filterValue.options
                ? "multiple"
                : "tags"
              : undefined
          }
        />
      }
      {props.filter.type === FilterType.INPUT &&
        <Input
          value={value}
          onChange={(e) => changeFilter(e.target.value)}
          placeholder={props.filter.name}
          suffix={editing ?
            <>
              <Button
                icon={<SettingOutlined />}
                size="small"
                shape="circle"
                type="text"
                onClick={showFilterFormModal}
              />
              <DeleteFilterIcon filter={props.filter} />
            </> : undefined} />
      }
      {filterFormVisible && !props.isTest &&
        <FilterForm
          filter={props.filter}
          onFinish={hideFilterFormModal}
        />
      }
    </>
  );
}

function DeleteFilterIcon(props: { filter: Filter }) {
  const [visible, setVisible] = useState(false);

  const onDelete = () => {
    dashboardService.deleteFilter(props.filter.name);
  };

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  return (
    <Popconfirm
      title="Delete filter"
      description="Are you sure to delete filter ?"
      open={visible}
      onConfirm={onDelete}
      onCancel={hide}
      okText="Yes"
      cancelText="No"
    >
      <Button
        icon={<DeleteOutlined />}
        size="small"
        shape="circle"
        type="text"
        danger
        onClick={show}
      />
    </Popconfirm>
  );
}

function AddFilterButton() {
  const [filterFormVisible, setFilterFormVisible] = useState(false);

  const showFilterFormModal = () => {
    setFilterFormVisible(true);
  };

  const hideFilterFormModal = () => {
    setFilterFormVisible(false);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={showFilterFormModal}
      >
        Add Filter
      </Button>
      {filterFormVisible &&
        <FilterForm
          onFinish={hideFilterFormModal}
        />
      }
    </>
  );
}

function FilterForm(props: {
  filter?: Filter;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);
  const [type, setType] = useState(props.filter?.type);
  const [customOptions, setCustomOptions] = useState(props.filter?.customOptions ?? false);
  const [customInitialValue, setCustomInitialValue] = useState(props.filter?.customOptions ?? false);
  const [multiValue, setMultiValue] = useState(props.filter?.multiValue ?? false);
  const [options, setOptions] = useState<string[] | string | undefined>(props.filter?.options);
  const [preview, setPreview] = useState<Filter | undefined>();
  const isTesting = useObservable(dashboardService.isTestingFilter);
  const isSaving = useObservable(dashboardService.isSavingFilter);

  const getFiltersOptions = () => {
    return dashboard?.dataset.filters.map((filter) => {
      return { label: filter, value: "{" + filter + "}" };
    });
  }

  const getFieldsOptions = () => {
    return dashboard?.dataset.fields.map((field) => {
      return { label: field, value: "{" + field + "}" };
    });
  }

  const getInitialValueOptions = () => {
    return [{
      label: "First",
      value: "{FIRST}"
    },
    {
      label: "Last",
      value: "{LAST}"
    },
    ...multiValue ? [{
      label: "All",
      value: "{ALL}"
    }] : []
    ];
  }

  const onSubmit = (values: Filter) => {
    const filter = Object.assign(new Filter(), values);
    if (!props.filter) {
      dashboardService.addFilter(filter).then((result) => {
        if (result) {
          onCancel();
        }
      });
    } else {
      dashboardService.updateFilter(filter).then((result) => {
        if (result) {
          onCancel();
        }
      });
    }
  };

  const onCancel = () => {
    props.onFinish();
  };

  const onTest = () => {
    form.validateFields().then((values) => {
      const filter = Object.assign(new Filter(), values);
      dashboardService.testFilter(filter).then((value) => {
        setPreview(value);
      });
    }, () => { });
  };

  const onChangeType = (value: FilterType) => {
    setType(value);
    form.setFieldValue("customOptions", false);
    onChangeCustomOption(false);
    form.setFieldValue("multiValue", false);
    onChangeMultiValue(false);
  }

  const onChangeCustomOptionEvent = (e: CheckboxChangeEvent) => {
    onChangeCustomOption(e.target.checked);
  }

  const onChangeCustomOption = (value: boolean) => {
    setCustomOptions(value);
    form.setFieldValue("options", undefined);
    onChangeOptions(undefined);
    form.setFieldValue("initialValue", undefined);
    if (!value) {
      form.setFieldValue("customInitialValue", false);
      setCustomInitialValue(false);
    }
  }

  const onChangeOptions = (value?: string[] | string) => {
    setOptions(value);
  }

  const onChangeCustomInitialValueEvent = (e: CheckboxChangeEvent) => {
    onChangeCustomInitialValue(e.target.checked);
  }

  const onChangeCustomInitialValue = (value: boolean) => {
    setCustomInitialValue(value);
    form.setFieldValue("initialValue", undefined);
  }

  const onChangeMultiValueEvent = (e: CheckboxChangeEvent) => {
    onChangeMultiValue(e.target.checked);
  }

  const onChangeMultiValue = (value: boolean) => {
    setMultiValue(value);
    if (customInitialValue || form.getFieldValue("initialValue") === "{ALL}") {
      form.setFieldValue("initialValue", undefined);
    }
  }



  const getCustomOptions = () => {
    if (options && Array.isArray(options)) {
      return options?.map((option) => { return { label: option, value: option } });
    }
    return [];
  }

  return (
    <Modal
      open
      onCancel={props.onFinish}
      title={props.filter ? "Edit Filter" : "New Filter"}
      footer={[
        <Button key="test" onClick={onTest}>
          Test
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isSaving}
        >
          {t("modal.new.submit")}
        </Button>
      ]}
    >
      <Form labelCol={{ span: 8 }} form={form} onFinish={onSubmit}>
        <Form.Item
          initialValue={props.filter?.name}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={props.filter?.field}
          labelAlign="left"
          label="Field"
          name="field"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            options={getFiltersOptions()}
            style={{ width: "100%" }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          initialValue={props.filter?.type}
          labelAlign="left"
          label="Type"
          name="type"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            options={filterTypeList}
            style={{ width: "100%" }}
            allowClear
            onChange={onChangeType}
          />
        </Form.Item>
        {type === "SELECT" &&
          <>
            <Form.Item
              initialValue={props.filter?.customOptions}
              labelAlign="left"
              label="Custom Options"
              name="customOptions"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeCustomOptionEvent} />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.options}
              labelAlign="left"
              label="Options"
              name="options"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Select
                options={customOptions ? undefined : getFieldsOptions()}
                mode={customOptions ? "tags" : undefined}
                allowClear
                open={customOptions ? false : undefined}
                suffixIcon={customOptions ? null : undefined}
                style={{ width: "100%" }}
                onChange={onChangeOptions}
              />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.multiValue}
              labelAlign="left"
              label="Multi Value"
              name="multiValue"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeMultiValueEvent} />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.customInitialValue}
              labelAlign="left"
              label="Custom Initial Value"
              name="customInitialValue"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeCustomInitialValueEvent} disabled={!customOptions} />
            </Form.Item>
          </>
        }
        <Form.Item
          initialValue={props.filter?.initialValue}
          labelAlign="left"
          label="Initial Value"
          name="initialValue"
        >
          {type === FilterType.INPUT ?
            <Input />
            :
            <Select
              options={customOptions && customInitialValue ? getCustomOptions() : getInitialValueOptions()}
              mode={customOptions && customInitialValue && multiValue ? "multiple" : undefined}
              allowClear
              style={{ width: "100%" }}
            />
          }
        </Form.Item>
      </Form>

      {(isTesting || preview) &&
        <Card title="Preview" >
          {isTesting ?
            <Loader /> :
            <DashboardFilter
              filter={preview!!}
              isTest
            />
          }
        </Card>
      }
    </Modal >
  );
}

function AddWidgetButton() {
  const [widgetFormVisible, setWidgetFormVisible] = useState(false);

  const showWidgetFormModal = () => {
    setWidgetFormVisible(true);
  };

  const hideWidgetFormModal = () => {
    setWidgetFormVisible(false);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={showWidgetFormModal}
      >
        Add Widget
      </Button>
      {widgetFormVisible &&
        <WidgetForm
          onFinish={hideWidgetFormModal}
        />
      }
    </>
  );
}

function WidgetForm(props: {
  widget?: Widget;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [type, setType] = useState(props.widget?.type);
  const [preview, setPreview] = useState<Widget | undefined>();
  const isTesting = useObservable(dashboardService.isTestingFilter);
  const isSaving = useObservable(dashboardService.isSavingFilter);

  const onChangeType = (value: WidgetType) => {
    setType(value);
  }

  const onBack = () => {
    setType(undefined);
  }

  const onSubmit = ({ name, customOptions, options, field, type, multiValue, customInitialValue, initialValue }:
    { name: string, customOptions: boolean, options?: string | string[], field: string, type: FilterType, multiValue: string, customInitialValue: boolean, initialValue?: string | string[] }) => {

    // const filter = Object.assign(new Filter(), {
    //   name: name,
    //   customOptions: customOptions,
    //   options: Array.isArray(options) ? JSON.stringify(options) : options,
    //   field: field,
    //   type: type,
    //   multiValue: multiValue,
    //   customInitialValue: customInitialValue,
    //   initialValue: Array.isArray(initialValue) ? JSON.stringify(initialValue) : initialValue
    // });
    // if (!props.widget) {
    //   dashboardService.addFilter(filter).then((result) => {
    //     if (result) {
    //       props.onFinish();
    //       form.resetFields();
    //     }
    //   });
    // } else {
    //   dashboardService.updateFilter(filter).then((result) => {
    //     if (result) {
    //       props.onFinish();
    //       form.resetFields();
    //     }
    //   });
    // }
  };

  const onCancel = () => {
    props.onFinish();
  };

  const onTest = () => {
    form.validateFields().then((values) => {
      const filter = Object.assign(new Filter(), values);
      dashboardService.testFilter(filter).then((value) => {
        setPreview(value);
      });
    }, () => { });
  };

  return (
    <Modal
      open
      onCancel={props.onFinish}
      title={props.widget ? "Edit Widget" : "New Widget"}
      footer={type ? [
        <Button key="test" onClick={onTest}>
          Test
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isSaving}
        >
          {t("modal.new.submit")}
        </Button>
      ] : []}
    >
      {!type ?
        <SelectWidgetType onChange={onChangeType} />
        :
        <>
          <Space style={{ marginBottom: 16 }}>
            <Button type="text" shape="circle" size="small" icon={<LeftOutlined />} onClick={onBack} />
            <Title level={5} style={{ margin: 0 }}>{getWidgetTypeLabel(type)}</Title>
          </Space>
          <Form labelCol={{ span: 8 }} form={form} onFinish={onSubmit}>
            <Form.Item
              initialValue={props.widget?.name}
              labelAlign="left"
              label="Name"
              name="name"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Input />
            </Form.Item>
          </Form>
          {(isTesting || preview) &&
            <Card title="Preview" >
              {isTesting ?
                <Loader /> :
                <WidgetCard
                  widget={preview!!}
                // isTest
                />
              }
            </Card>
          }
        </>
      }
    </Modal >
  );
}

function SelectWidgetType(props: {
  onChange: (value: WidgetType) => void
}) {

  const getLogo = (type: WidgetType) => {
    switch (type) {
      case WidgetType.Card:
        return cardChart
      case WidgetType.Line:
        return lineChart;
      case WidgetType.Pie:
        return pieChart;
      case WidgetType.Bar:
        return barChart;
      case WidgetType.Table:
        return tableChart;
    }
  }

  return (
    <Row gutter={[16, 16]}>
      {widgetTypeList.map((type) =>
        <Col key={type.value} sm={12} xs={24}>
          <Card onClick={() => { props.onChange(type.value) }} style={{ cursor: "pointer" }} >
            <center>
              <Title level={4}>{type.label}</Title>
              <img src={getLogo(type.value)} width={100} height={100} alt="Logo" />
            </center>
          </Card>
        </Col>
      )}
    </Row>
  );
}
import { adminOrganizationService, authService } from "@/services";
import { Button, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import Loader from "../components/loader";

const { Title } = Typography;

export default function SelectOrganization() {
  const organizations = useObservable(adminOrganizationService.organizations);
  const claims = useObservable(authService.claims);

  useEffect(() => {
    adminOrganizationService.getOrganizations();
  }, []);

  const onSwitch = (organizationId: string) => {
    authService.selectOrganization(organizationId).then((response) => {
      if (response) {
        window.location.assign("/");
      }
    });
  }

  if (!organizations) {
    return <Loader />;
  }

  return <div className="switch-organization-page">
    <Row justify="center" align="middle" style={{ "height": "100%" }}>
      <Col>
        <Title level={3} style={{ "textAlign": "center" }}>Select Organization</Title>
        {
          organizations?.map((organization) => (
            <Button
              key={organization.id}
              type="link"
              size="large"
              block
              disabled={claims?.organizationId === organization.id}
              onClick={() => onSwitch(organization.id)}>
              {organization.name}{claims?.organizationId === organization.id ? " (current)" : ""}
            </Button>
          ))
        }
      </Col>
    </Row>
  </div >;
}

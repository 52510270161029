import { BehaviorSubject, map } from "rxjs";
import { authService } from ".";
import {
  Account,
  SelectAccount,
  VendorCentralCredential,
} from "@/models/vendor-central-credential";

export class VendorCentralCredentialService {
  isFetching = new BehaviorSubject<boolean>(false);
  vendorCentralCredentials = new BehaviorSubject<VendorCentralCredential[]>([]);
  allAccounts = this.vendorCentralCredentials.pipe(
    map((credentials) =>
      credentials
        .flatMap((credential) =>
          credential.accounts.map(
            (account) => new SelectAccount(account.name, account.payeeCode)
          )
        )
        .sort((a, b) => (a.value < b.value ? -1 : 1))
    )
  );
  isFetchingOne = new BehaviorSubject<boolean>(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);
  vendorCentralCredential = new BehaviorSubject<VendorCentralCredential | null>(
    null
  );

  dispose = async () => {
    this.isFetching.next(false);
    this.vendorCentralCredentials.next([]);
  };

  disposeCredential = async () => {
    this.vendorCentralCredential.next(null);
    this.isFetchingOne.next(false);
    this.isAdding.next(false);
    this.isUpdating.next(false);
    this.isDeleting.next(false);
  };

  getVendorCentralCredentials = async () => {
    this.refresh();
  };

  refresh = async () => {
    try {
      this.isFetching.next(true);
      const response = await authService.authFetch(
        "/vendor-central-credentials",
        {
          method: "GET",
        }
      );
      if (response?.ok) {
        this.vendorCentralCredentials.next(
          ((await response.json()) as VendorCentralCredential[]).map((x) =>
            Object.assign(new VendorCentralCredential(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getCredential = async (id: string) => {
    try {
      this.isFetchingOne.next(true);
      const response = await authService.authFetch(
        "/vendor-central-credentials/" + id,
        {
          method: "GET",
        }
      );
      if (!!response?.ok) {
        this.vendorCentralCredential.next(
          Object.assign(new VendorCentralCredential(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  addCredential = async (email: string, password: string, otpSecret: string) => {
    try {
      this.isAdding.next(true);
      const response = await authService.authFetch("/vendor-central-credentials", {
        method: "POST",
        body: JSON.stringify({ email, password, otpSecret }),
      });
      if (!!response?.ok) {
        this.refresh();
      }
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
    return false;
  };

  updateCredential = async (id: string, email: string, password: string, otpSecret: string, accounts: Account[]) => {
    try {
      this.isUpdating.next(true);
      const response = await authService.authFetch("/vendor-central-credentials", {
        method: "PATCH",
        body: JSON.stringify({ id, email, password, otpSecret, accounts }),
      });

      if (!!response?.ok) {
        this.vendorCentralCredential.next(Object.assign(this.vendorCentralCredential.value!!, { email, accounts }));
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  };

  deleteCredential = async (id: string) => {
    try {
      this.isDeleting.next(true);
      const response = await authService.authFetch("/vendor-central-credentials/" + id, {
        method: "DELETE",
      });
      return !!response?.ok;
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  };
}



import { HomeOutlined, TeamOutlined } from "@ant-design/icons";
import { Menu, MenuProps, Typography } from "antd";
import { useLocation, Outlet, Link } from "react-router-dom";
import "./index.less";

const { Title } = Typography;

export default function AdminSettingsPage() {
  const location = useLocation();

  const currentKey = location.pathname.split("/").at(3) ?? "users";

  const menuItems: MenuProps["items"] = [
    {
      key: "users",
      label: <Link to="users">Users</Link>,
      icon: <TeamOutlined />,
    },
    {
      key: "organizations",
      label: <Link to="organizations">Organizations</Link>,
      icon: <HomeOutlined />,
    },
  ];

  return (
    <div className="admin-page">
      <Title level={3}>Admin Settings</Title>

      <Menu mode="horizontal" selectedKeys={[currentKey]} items={menuItems} />
      <div className="admin-container">
        <Outlet />
      </div>
    </div>
  );
}

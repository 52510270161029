import { SyncOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Row, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VendorCentralCredential } from "@/models/vendor-central-credential";
import { authService, vendorCentralCredentialService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import "./index.less";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Title } = Typography;

export default function VendorCentralCredentialsPage() {
  const columns = [
    {
      title: "Email",
      key: "name",
      sorter: {
        compare: (a: VendorCentralCredential, b: VendorCentralCredential) =>
          a.email > b.email ? -1 : 1,
        multiple: 3,
      },
      render: (_: string, row: VendorCentralCredential) => (
        <Link to={row.id}>{row.email}</Link>
      ),
    },
  ];

  const isFetching = useObservable(vendorCentralCredentialService.isFetching);
  const vendorCentralCredentials = useObservable(
    vendorCentralCredentialService.vendorCentralCredentials
  );
  const [searchText, setSearchText] = useState("");
  const admin = useObservable(authService.isAdmin);

  useEffect(() => {
    vendorCentralCredentialService.getVendorCentralCredentials();
  }, []);

  const onRefresh = () => {
    vendorCentralCredentialService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  return (
    <div className="vendor-central-credentials-page">
      <Title level={3}>Vendor Central Credentials</Title>
      <Row className="actions" justify="start" gutter={[8, 8]}>
        {admin &&
          <Col className="action" sm={8} xs={24}>
            <AddVendorCentralCredentialButton />
          </Col>
        }
        <Col className="action" sm={8} xs={24}>
          <Button icon={<SyncOutlined spin={isFetching} />} onClick={onRefresh}>
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search placeholder="Search account" onSearch={onSearch} />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={vendorCentralCredentials.filter((vendorCentralCredential) =>
          vendorCentralCredential.email.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
      />
    </div>
  );
}

function AddVendorCentralCredentialButton() {
  const [newVendorCentralCredentialVisible, setNewVendorCentralCredentialVisible] =
    useState(false);

  const showNewVendorCentralCredentialModal = () => {
    setNewVendorCentralCredentialVisible(true);
  };

  const hideNewVendorCentralCredentialModal = () => {
    setNewVendorCentralCredentialVisible(false);
    vendorCentralCredentialService.refresh();
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewVendorCentralCredentialModal}
      >
        New Credentials
      </Button>
      <VendorCentralCredentialNewForm
        visible={newVendorCentralCredentialVisible}
        onFinish={hideNewVendorCentralCredentialModal}
      />
    </>
  );
}

function VendorCentralCredentialNewForm(props: {
  visible: boolean;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSubmit = ({
    email,
    password,
    otpSecret,
  }: {
    email: string;
    password: string;
    otpSecret: string;
  }) => {
    setLoading(true);
    vendorCentralCredentialService
      .addCredential(email, password, otpSecret)
      .then((response) => {
        setLoading(false);
        if (response) {
          message.success("Credential created successfully", 4);
          props.onFinish();
          form.resetFields();
        }
      });
  };

  const onCancel = () => {
    props.onFinish();
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={props.onFinish}
      title="New Credential"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Otp Secret"
          name="otpSecret"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-organization-page {
  height: 100%;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/select-organization/index.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EAEA,uBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".switch-organization-page {\n    height: 100%;\n    // display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Chart, { GoogleChartWrapperChartType } from "react-google-charts";

export enum WidgetType {
  Card = "CARD",
  Line = "LINE",
  Bar = "BAR",
  Pie = "PIE",
  Table = "TABLE",
}

export const widgetTypeList = [
  {
    value: WidgetType.Card, label: "Card", example: <Chart chartType={"Line"} width="100%" height="100%" />
  },
  { value: WidgetType.Line, label: "Line" },
  { value: WidgetType.Bar, label: "Bar" },
  { value: WidgetType.Pie, label: "Pie" },
  { value: WidgetType.Table, label: "Table" },
];

export const getWidgetTypeLabel = (type: WidgetType) => {
  return widgetTypeList.find((item) => item.value === type)?.label;
}

export const widgetTypeToChart = (type: WidgetType): GoogleChartWrapperChartType | undefined => {
  switch (type) {
    case WidgetType.Line:
      return "Line";
    case WidgetType.Bar:
      return "Bar";
    case WidgetType.Pie:
      return "PieChart";
  }
  return undefined;
}